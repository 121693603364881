import gql from 'graphql-tag';
import { produce } from 'immer';
import { Config, Nullable } from 'types';
import { StateContainer } from './state';

interface LocalConfig {
	configInitialized?: boolean;
  isAdmin: boolean;
  activeTenant: null | {
    name: string;
    id: number;
  };
};
const config: StateContainer<Nullable<Config> & LocalConfig> = {
  defaults: {
    configInitialized: false,
    tenantLink: null,
    tenants: null,
    userRestId: null,
    maxUploadSize: null,
    contextPath: '/app',
    csrf: null,
    language: null,
		userHref: null,
    locale: null,
    tenantHref: null,
    userId: null,
    activeTenant: null,
    systemAdmin: false,
    tenantAdmin: false,
    isAdmin: false,
    apiUrl: null,
    blobApiUrl: null,
    termsAccepted: true,
    tenantId: null,
    fullUserName: null,
  },
  resolvers: {
    Mutation: {
      updateConfig: (_, { config }, { cache }) => {
        const stateConfig = produce(config, (config: any) => {
          config.configInitialized = true;
          delete config.csrf;

          if(!config.permissions) config.permissions = [];
          config.permissions = config.permissions.map((permission: any) => {
            return { ...permission, __typename: 'Permission' };
          })

          if(config.tenants) {
            config.isAdmin = config.systemAdmin || config.tenantAdmin;
            const activeTenant = config.tenants[config.tenantId];
            config.activeTenant = {
              name: activeTenant || '',
              id: config.tenantId,
              __typename: 'tenant',
            };

            config.userTenants = Object.entries(config.tenants).map(([id, name]) => {
              return {
                name,
                id: Number(id),
                __typename: 'tenant',
              };
            });
            delete config.tenants;
          } else {
            config.isAdmin = false;
            config.systemAdmin = false;
            config.tenantAdmin = false;
            config.activeTenant = null;
          }
        });

        cache.writeData({ data: stateConfig });
        return null;
      },
      setActiveTenant: (_, { tenantId }, { cache }) => {
        const GET_TENANTS = gql`
          {
            userTenants @client {
              name
              id
            }
          }
        `;

        interface Result {
          userTenants: Array<{
            name: string;
            id: number;
          }>;
        };

        const result = cache.readQuery<Result>({ query: GET_TENANTS });
        if(!result) return;

        const tenant = result.userTenants.find(t => t.id === tenantId);
        if(!tenant) return;

        cache.writeData({
          data: {
            activeTenant: {
              id: tenant.id,
              name: tenant.name,
              __typename: 'tenant',
            },
          },
        });
        return null;
      },
    },
  },
};

export default config;
